import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import CommonLink from '../components/CommonLink'
import SVGBorderButton from '../components/SVG/SVGBorderButton'

export const ResourceTemplate = ({
  title,
  link,
  buttonText,
  description,
  datePublished,
  themes,
  plans,
  initiatives,
  source,
}) => {
  //console.log(datePublished)
  //console.log(themes)
  //console.log(plans)
  //console.log(initiatives)
  return (
    <div className="result w-full mt-6 py-6 px-8 bg-black-lightest overflow-hidden">
      <h1 className="text-2xl text-blue font-header">{title}</h1>
      <div className="pb-4 font-body text-black-darker">
        {datePublished ? <p>{`Year: ${datePublished.toString()}`}</p> : null}
        <p>{description}</p>
        {source ? <p className="text-sm">{`Source: ${source}`}</p> : null}
        {themes ? (
          <p className="text-sm">
            Themes: <span className="text-xs">{themes.join(', ')}</span>
          </p>
        ) : null}
        {plans ? (
          <p className="text-sm">
            Plans: <span className="text-xs">{plans.join(', ')}</span>
          </p>
        ) : null}
        {initiatives ? (
          <p className="text-sm">
            Initiatives:{' '}
            <span className="text-xs">{initiatives.join(', ')}</span>
          </p>
        ) : null}
      </div>
      <SVGBorderButton
        label={buttonText}
        to={link}
        bgColor="#189CD8"
        bgColorHover="#9AC5E9"
        borderColor="#189CD8"
        borderColorHover="#189CD8"
        textColor="white"
        textColorHover="#243D91"
      />
    </div>
  )
}

ResourceTemplate.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  buttonText: PropTypes.string,
  description: PropTypes.string,
  source: PropTypes.string,
  themes: PropTypes.array,
  plans: PropTypes.array,
  initiatives: PropTypes.array,
  datePublished: PropTypes.number,
}

const Resource = ({ data }) => {
  const { markdownRemark: resource } = data

  return (
    <ResourceTemplate
      title={resource.frontmatter.title}
      link={resource.frontmatter.link}
      buttonText={resource.frontmatter.button_text}
      description={resource.frontmatter.description}
      source={resource.frontmatter.source}
      themes={resource.frontmatter.themes}
      plans={resource.frontmatter.plans}
      initiatives={resource.frontmatter.initiatives}
      dateModified={resource.frontmatter.date_modified}
      datePublished={resource.frontmatter.date_published}
    />
  )
}

Resource.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Resource

export const pageQuery = graphql`
  query ResourceByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        link
        button_text
        source
        themes
        plans
        initiatives
        date_modified
        date_published
      }
    }
  }
`
